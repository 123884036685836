<template lang="pug">
v-app
    v-main.white()
        v-app-bar.topBar(color='brand', dark, app, clipped-left)
            a.d-flex(@click='goToLogin', justify='center', align='center')
                v-img.mr-4.mt-1(src='/DorsettControls_Symbol_White.png', max-height='24', max-width='30')
            v-btn.white--text(text, @click='goToLogin') Dorsett Controls Security Bulletins
        v-container
            v-navigation-drawer(permanent, dark, color="brand", app, clipped)
                v-list
                    v-subheader Filters
                    v-list-item(v-for='filter in filters', :key='filter.year', dense)
                        v-list-item-content
                            v-list-item-title
                                v-checkbox.ml-2(v-model='filter.active', :label='filter.year.toString()')
            template(v-if='loadingBulletins')
                v-row
                    v-col.text-center
                        v-progress-circular(color='brand', indeterminate)
                        span.ml-2.black--text Loading bulletins...
            template(v-if='!loadingBulletins && filteredBulletins.length === 0')
                v-row
                    v-col.text-center
                        span.ml-2.black--text No bulletins found.
            v-expansion-panels(light, v-model='expandedPanel')
                v-expansion-panel(v-for='bulletin of filteredBulletins', :key='bulletin.id')
                    v-hover(v-slot='{hover}')
                        v-expansion-panel-header.brand--text
                            span ({{bulletin.publishDate}}) {{bulletin.title}}
                                v-icon.ml-2(v-if='hover', @click.stop='copyLink(bulletin.id)', title='Copy link') link
                    v-expansion-panel-content
                        .content(v-html='bulletin.content')

</template>

<script>

export default {
    components: {
    },
    data () {
        return {
            bulletins: [],
            filters: [],
            loadingBulletins: true,
            expandedPanel: null
        };
    },
    methods: {
        /**
         * Go to the login page (or home if the user is logged in)
         */
        goToLogin () {
            this.$router.push('/home');
        },
        /**
         * Gets the year the bulletin was published.
         * 
         * @param {string} dateString 
         * @returns {number} Year the bulletin was published
         */
        getBulletinYear (dateString) {
            const date = new Date(dateString);
            return date.getFullYear();
        },
        /**
         * Gets an array of years to build filters from.
         * 
         * @returns {number[]}
         */
        getYears () {
            const years = [];
            const dates = this.bulletins.map(bulletin => bulletin.publishDate);
            for (const dateString of dates) {
                const year = this.getBulletinYear(dateString);
                if (!years.includes(year)) {
                    years.push(year);
                }
            }
            return years;
        },
        /**
         * Builds an array of filters based on the years.
         * 
         * @returns {object[]}
         */
        buildFilters () {
            const years = this.getYears();
            const filters = years.map(year => {return {year, active: true};});
            this.filters = filters;
        },
        /**
         * Opens the bulletin based on the bulletin id
         * 
         * @param {number} id 
         */
        openBulletin (id) {
            const bulletin = this.filteredBulletins.find(bulletin => bulletin.id === id);
            if (bulletin) {
                this.expandedPanel = this.filteredBulletins.indexOf(bulletin);
            }
        },
        /**
         * Copies the link to the bulletin to the clipboard.
         * 
         * @param {number} id 
         */
        copyLink (id) {
            const baseUrl = `${window.location.protocol}//${window.location.hostname}:${CONFIG.dev ? CONFIG.devPort : ''}`;
            navigator.clipboard.writeText(`${baseUrl}/#${this.$route.path}?bulletin=${id}`);
        }
    },
    computed: {
        activeFilters () {
            return this.filters.filter(filter => filter.active);
        },
        filteredBulletins () {
            const bulletins = [];
            const yearFilters = this.activeFilters.map(filter => filter.year);
            for (const bulletin of this.bulletins) {
                const bulletinYear = this.getBulletinYear(bulletin.publishDate);
                if (yearFilters.includes(bulletinYear)) {
                    bulletins.push(bulletin);
                }
            }
            return bulletins.sort((a, b) => {
                return new Date(b.publishDate) - new Date(a.publishDate);
            });
        },
        /**
         * ID of bulletin to open
         */
        targetBulletinId () {
            return this.$route.query.bulletin;
        },
    },
    async mounted () {
        const response = await this.axios.get('/getsecuritybulletins');
        this.bulletins = response.data;
        this.loadingBulletins = false;
        this.buildFilters();
        if (this.targetBulletinId) {
            this.openBulletin(parseInt(this.targetBulletinId));
        }
    },
};
</script>

<style lang="scss">
.content a {
    color: blue;
}

</style>
